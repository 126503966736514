<template>
  <div class="bg_lightgray">
    <div id="section_spec" class="content anchor">
      <div class="content_header">
        <p>VRカメラ仕様</p>
      </div>
      <div class="content_body">
        <p class="content_body__header">
          技術仕様
        </p>
        <table>
          <tr>
            <th>Dimension(L x W x H)mm</th>
            <td>334 x 300 x 438</td>
          </tr>
          <tr>
            <th>Weight</th>
            <td>~10kg</td>
          </tr>
          <tr>
            <th>Camera</th>
            <td>Canon EOS 5D Mark III/ Mark IV/ Kiss Xi9</td>
          </tr>
          <tr>
            <th>Photo Files</th>
            <td>*JPEG/ *RAW(Canon)</td>
          </tr>
          <tr>
            <th>Resolution</th>
            <td>50K/ 60K/ 100K/ 200K</td>
          </tr>
          <tr>
            <th>Stitching</th>
            <td>Power</td>
          </tr>
          <tr>
            <th>Power</th>
            <td>24V, 6A Adapter</td>
          </tr>
          <tr>
            <th>Battery Capacity</th>
            <td>139,200mAh(3.6V) 500Wh</td>
          </tr>
          <tr>
            <th>Operating Temperatate</th>
            <td>0 -40℃</td>
          </tr>
          <tr>
            <th>Operating System</th>
            <td>Windows 10</td>
          </tr>
        </table>

        <p class="content_body__header">
          VRカメラの基本サイズ
        </p>
        <div
          class="container content_body__size d-flex justify-content-between"
        >
          <div class="row">
            <div class="col-sm-5 px-0">
              <img
                class="content_body__image"
                v-lazy="require('@/assets/camera_tall.jpg')"
                alt=""
              />
            </div>
            <div class="col-sm-6 px-0 offset-sm-1 imageWithArrow">
              <img
                class="content_body__image"
                v-lazy="require('@/assets/camera_with_height.jpg')"
                style="object-position: right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class Spec extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.arrow_wrapper {
  position: relative;
  display: inline-block;
  margin: 12px;
  font-size: 0.7em;
  padding: 0;
  height: 70%;
  top: 15%;
}
.imageWithArrow {
  display: flex;
}
.content_body {
  text-align: center;
  &__header {
    font-size: 1.4rem;
    margin: 40px auto;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 80px;
}
table th,
table td {
  padding: 10px 0;
  text-align: start;
  padding-left: 24px;
}
table tr {
  &:nth-child(odd) {
    background-color: var(--white);
  }
  &:nth-child(even) {
    background-color: var(--bluegray);
  }
}
</style>
