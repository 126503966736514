<template>
  <div id="section_service" class="content anchor">
    <div class="container">
      <div class="content_header__home">
        <p class="en_title">SERVICE</p>
        <p>事業内容</p>
      </div>
      <div class="row text-center">
        <div class="card col-md-4 py-5 bg-secondary color-white">
          <img src="@/assets/culiarch_logo.png" alt="" class="logo_image" />
        </div>
        <div class="card col-md-4 bg-lightgray">
          <i class="fas fa-vr-cardboard fa-3x color-blue"></i>
          <p class="service_title">超高画質360度VR画像</p>
          <p>
            超高画質360度パノラマ画像の撮影・コンテンツ制作サービス、および超高画質VRカメラを提供する
          </p>
          <div class="item button-jittery" style="--bg-color: #f1c40f;">
            <router-link to="/vr_camera">
              <button>
                More Info
              </button>
            </router-link>
          </div>
        </div>
        <div class="card col-md-4 bg-secondary color-white">
          <i class="fas fa-print fa-3x"></i>
          <p class="service_title">高精細スキャニング</p>
          <p>
            世界に誇る貴重な文化財の今を、超高精細スキャニング技術を用いて完全デジタル化して、今後の保存・修復に活用することができる
          </p>
        </div>
        <div class="card col-md-4 bg-lightgray">
          <i class="fas fa-cubes fa-3x color-blue"></i>
          <p class="service_title">3次元測量撮影</p>
          <p>
            複数カメラを使って写真測量やレーザーを使用した3D計測を行い、3Dモデリングを行う
          </p>
        </div>
        <div class="card col-md-4 bg-secondary color-white">
          <i class="fas fa-camera fa-3x"></i>
          <p class="service_title">ドローン空撮</p>
          <p>
            ドローンによる測量的な撮影を行い、文化財建物の3次元デジタル化を行う
          </p>
        </div>
        <div class="card col-md-4 bg-lightgray">
          <i class="fas fa-cloud fa-3x color-blue"></i>
          <p class="service_title">コンテンツ撮影</p>
          <p>
            最先端技術を用いて撮影した素材を使用して、観光やアーカイブ化などの用途に応じたコンテンツを作成する
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class Service extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.logo_image {
  width: 40%;
  margin: auto;
  opacity: 0.3;
}
.card {
  align-items: center;
  padding: 32px 16px;
  i {
    width: 40%;
    margin: 8px 0 16px;
  }
  @media (max-width: 576px) {
    p {
      margin-bottom: 0;
    }
  }
}
.service_title {
  padding: 12px 0;
  font-size: 1.2rem;
  font-weight: bold;
}
.bg-lightgray {
  background-color: var(--lightgray);
}
.color-blue {
  color: var(--darkblue);
}
.color-white {
  color: var(--white);
}
button {
  background: var(--lightblue);
  color: var(--white);
  border: none;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in-out;
  letter-spacing: 2px;
  margin-top: 16px;
}

.button-jittery button {
  animation: jittery 3.5s infinite;
  &:hover {
    animation: heartbeat 0.6s infinite;
    background-color: var(--darkblue);
  }
}
@keyframes jittery {
  5%,
  80% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  15%,
  50% {
    transform: scale(1.15);
  }
  20%,
  55% {
    transform: scale(1.15) rotate(-5deg);
  }
  25%,
  60% {
    transform: scale(1.15) rotate(5deg);
  }
  30%,
  65% {
    transform: scale(1.15) rotate(-3deg);
  }
  35%,
  70% {
    transform: scale(1.15) rotate(2deg);
  }
  40%,
  75% {
    transform: scale(1.15) rotate(0);
  }
}
@keyframes heartbeat {
  50% {
    transform: scale(1.1);
  }
}
</style>
