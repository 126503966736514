<template>
  <video muted autoplay playsinline loop :src="src" loading="lazy" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    src: String
  }
})
export default class Video extends Vue {
  src!: string;
}
</script>

<style scoped lang="scss">
video {
  width: 100%;
}
</style>
