
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    src: String
  }
})
export default class VR extends Vue {
  src!: string;
}
