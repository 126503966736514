
import { Options, Vue } from "vue-class-component";

import TopPageButton from "@/components/atoms/TopPageButton.vue";

@Options({
  components: {
    TopPageButton
  }
})
export default class Footer extends Vue {}
