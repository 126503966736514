
import { Vue } from "vue-class-component";

export default class Header extends Vue {
  private scrollY = 0;
  private sectionOffsetTop: number[] = [];

  currentNavi() {
    if (
      this.sectionOffsetTop[0] - 30 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[1] - 30
    ) {
      return "service";
    } else if (
      this.sectionOffsetTop[1] - 30 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[2] - 30
    ) {
      return "about";
    } else if (this.sectionOffsetTop[2] - 30 <= this.scrollY) {
      return "contact";
    } else {
      return "";
    }
  }

  mounted() {
    window.addEventListener("scroll", () => {
      this.pushScrollY();
    });
    window.addEventListener("resize", () => {
      this.pushScrollY();
    });
    window.addEventListener("load", () => {
      this.pushElementOffsetTop();
    });
  }

  private pushScrollY() {
    this.scrollY = window.scrollY;
  }

  private pushElementOffsetTop() {
    const targets = ["section_service", "section_about", "section_contact"];
    targets.forEach(target => {
      const element = document.getElementById(target);
      let offsetTop = 0;
      if (element != null) {
        offsetTop = Math.round(
          window.scrollY + element.getBoundingClientRect().top
        );
      }
      this.sectionOffsetTop.push(offsetTop);
    });
  }

  closeModal() {
    const navbarToggler = document.getElementById("navbarToggler");
    const menuBar = document.getElementById("menuBar");
    if (navbarToggler && menuBar) {
      navbarToggler.classList.remove("show");
      menuBar.setAttribute("aria-expanded", "false");
    }
  }
}
