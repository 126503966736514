<template>
  <div class="bg_lightgray">
    <div id="section_about" class="content anchor">
      <div class="container">
        <div class="content_header__home">
          <p class="en_title">ABOUT</p>
          <p>会社概要</p>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            会社名<br />
            Company Name
          </div>
          <div class="col-sm-8 pl-5 info">
            株式会社カリアーク<br />Cultural Images Archive Co., Ltd.
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            設立<br />
            Establish
          </div>
          <div class="col-sm-8 pl-5 info">
            2018年 11月 26日<br />Novembar 26, 2018
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            所在地<br />
            Location
          </div>
          <div class="col-sm-8 pl-5 info">
            〒615-8245<br />
            京都市西京区御陵大原1-36<br />
            京大桂ベンチャープラザ北館309
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            電話番号<br />
            Phone Number
          </div>
          <div class="col-sm-8 pl-5 info">075-754-7710</div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            FAX<br />
            Fax Number
          </div>
          <div class="col-sm-8 pl-5 info">075-754-7710</div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            代表取締役<br />
            CEO
          </div>
          <div class="col-sm-8 pl-5 info">井手亜里<br />Ari Ide</div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3 pl-sm-5 col-form-label">
            事業内容<br />
            Business Lineup
          </div>
          <div class="col-sm-8 pl-5 info">
            超高画質VRカメラ製造・販売、スキャナ製造・販売、コンテンツ制作、デジタルアーカイブシステムの構築・管理
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import VR from "./atoms/VR.vue";

@Options({
  components: {
    VR
  }
})
export default class About extends Vue {}
</script>

<style scoped lang="scss">
.content {
  .col-form-label {
    @media (min-width: 576px) {
      border-right: 2px solid var(--darkblue);
    }
  }
}
.info {
  @media (max-width: 575px) {
    margin-left: 2rem;
    padding-left: 1rem !important;
    border-left: 3px solid var(--darkblue);
  }
}
</style>
