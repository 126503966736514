<template>
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" :src="src" loading="lazy"></iframe>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    src: String
  }
})
export default class VR extends Vue {
  src!: string;
}
</script>

<style scoped lang="scss"></style>
