<template>
  <div>
    <HomeHeroArea />
    <Header />
    <div class="main">
      <Service />
      <About />
      <Contact />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HomeHeroArea from "@/components/HomeHeroArea.vue";
import Service from "@/components/Service.vue";
import About from "@/components/About.vue";
import PracticalUse from "@/components/PracticalUse.vue";
import Spec from "@/components/Spec.vue";
import Contact from "@/components/Contact.vue";

@Options({
  components: {
    Header,
    Footer,
    HomeHeroArea,
    Service,
    About,
    PracticalUse,
    Spec,
    Contact
  }
})
export default class Home extends Vue {}
</script>

<style scoped lang="scss"></style>
