
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { useReCaptcha } from "vue-recaptcha-v3";
import { Field, Form } from "vee-validate";

import MessageModal from "./atoms/MessageModal.vue";

@Options({
  components: {
    MessageModal,
    Field,
    Form
  }
})
export default class Contact extends Vue {
  companyName = "";
  userName = "";
  email = "";
  phone = "";
  inquiry = "";
  content = "";
  submitKey = "1234567";
  message = "";

  private recaptcha = useReCaptcha();

   isRequired(value: string | number) {
    return value ? true : "入力必須です / Required field";
  }

   async onSubmit() {
    const formData = new FormData();

    formData.append("companyName", this.companyName);
    formData.append("userName", this.userName);
    formData.append("email", this.email);
    formData.append("phone", this.phone);
    formData.append("inquiry", this.inquiry);
    formData.append("content", this.content);
    formData.append("submitKey", this.submitKey);

    const requestUrl = "/sendemail.php/";

    if (!this.recaptcha) {
      return;
    }
    const { executeRecaptcha, recaptchaLoaded } = this.recaptcha;
    await recaptchaLoaded();
    const token = await executeRecaptcha("login");
    formData.append("g-recaptcha-response", token);
    formData.append("action", "login");

    axios
      .post(requestUrl, formData, {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
      .then(() => {
        this.message = "メールを送信しました";
        this.companyName = "";
        this.userName = "　";
        this.email = "　";
        this.phone = "　";
        this.inquiry = "　";
        this.content = "";
        this.showModal();
      })
      .catch(() => {
        this.message = "メールの送信に失敗しました";
        this.showModal();
      });
  }

  private showModal() {
    const messageModal = document.getElementById("messageModal");
    if (messageModal) {
      messageModal.classList.replace("msg_hide", "msg_appear");
      setTimeout(() => {
        messageModal.classList.replace("msg_appear", "msg_hide");
      }, 4000);
    }
  }
}
