<template>
  <div class="topImage">
    <div class="topImage__colorFilter">
      <img src="@/assets/top_bg.png" alt="" />
    </div>
    <p class="topImage__text">
      <span class="blue_outline">Culiarch</span><br />
      Cultural Images Archive Co., Ltd.
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class HomeHeroArea extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.topImage {
  position: relative;
  width: 100%;
  height: 42vw;
  @media (max-width: 567px) {
    height: 56vw;
  }
  @media (min-width: 568px) and (max-width: 992px) {
    height: calc(100vh - 56px);
    max-height: 56vw;
  }
  &__colorFilter,
  &__text {
    position: absolute;
    bottom: 0;
  }
  &__colorFilter {
    height: 100%;
    width: 100%;
    background-color: var(--darkblue);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.4;
    }
  }
  &__text {
    top: 50%;
    left: 50%;
    width: fit-content;
    height: fit-content;
    transform: translate(-50%, -50%);
    font-family: sans-serif;
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    letter-spacing: 3px;
    line-height: 1.4;
    z-index: 10;
    opacity: 0.8;
    @media (max-width: 575px) {
      font-size: 1rem;
    }
    @media (min-width: 576px) and (max-width: 992px) {
      font-size: 1.4rem;
    }
  }
}
.blue_glow {
  color: var(--white);
  text-shadow: 0 0 12px var(--lightblue);
  font-size: 80px;
}
.blue_outline {
  color: var(--lightblue);
  text-shadow: 1px 1px 0 var(--white), -1px 1px 0 var(--white),
    1px -1px 0 var(--white), -1px -1px 0 var(--white);
  font-size: 80px;
}
@media (max-width: 992px) {
  // .topImage__text {
  //   height: 100%;
  //   margin-left: 28px;
  //   padding-top: 32px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   font-size: 1.4rem;
  // }
  .blue_glow,
  .blue_outline {
    font-size: 2.5rem;
  }
}
</style>
