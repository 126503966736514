<template>
  <transition name="fade">
    <div id="pagetop" :style="bgColor" v-show="scY > 300" @click="toTop">
      <p>PageTop</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="38"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        stroke-width="1"
        stroke-linecap="square"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    bgColor: String
  }
})
export default class TopPageButton extends Vue {
  private scY = 0;
  private scTimer = 0;

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }

  private toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  private handleScroll() {
    if (this.scTimer) return;
    this.scTimer = setTimeout(() => {
      this.scY = window.scrollY;
      clearTimeout(this.scTimer);
      this.scTimer = 0;
    }, 100);
  }
}
</script>

<style scoped lang="scss">
#pagetop {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 15px;
  bottom: 0;
  cursor: pointer;
  p {
    margin: 4px 0 0;
    text-align: center;
    font-size: 12px;
    color: var(--white);
  }
  @media (max-width: 576px) {
    right: 8px;
    height: 56px;
    a {
      height: 56px;
    }
  }
  a {
    display: block;
    width: 60px;
    height: 60px;
    text-decoration: none;
    text-align: center;
    &::before {
      content: "Page Top";
      font-size: 12px;
      color: var(--white);
      display: inline-block;
      width: 60px;
    }
    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f106";
      font-size: 28px;
      color: var(--white);
      display: inline-block;
    }
  }
}
</style>
