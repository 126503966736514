
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
    bgColor: String
  }
})
export default class MessageModal extends Vue {
  msg!: string;
  bgColor!: string;
}
