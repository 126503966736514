import { createApp } from "vue";
import lazyPlugin from "vue3-lazy";
import { VueReCaptcha } from "vue-recaptcha-v3";
// import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"; // vue v3 is not supported yet

import "bootstrap/dist/css/bootstrap.css"; // see App.vue
import "bootstrap-vue/dist/bootstrap-vue.css"; // see App.vue

import "./registerServiceWorker";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  .use(lazyPlugin, {
    loading: "",
    error: ""
  })
  .use(VueReCaptcha, {
    siteKey: "6LeMpQEaAAAAAKdxexBZ1B4aThPjaOoa7gNGcDaK",
    loaderOptions: {
      autoHideBadge: true
    }
  })
  // .use(BootstrapVue).use(BootstrapVueIcons) // vue v3 is not supported yet
  .mount("#app");
