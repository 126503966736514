
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    bgColor: String
  }
})
export default class TopPageButton extends Vue {
  private scY = 0;
  private scTimer = 0;

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }

  private toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  private handleScroll() {
    if (this.scTimer) return;
    this.scTimer = setTimeout(() => {
      this.scY = window.scrollY;
      clearTimeout(this.scTimer);
      this.scTimer = 0;
    }, 100);
  }
}
