
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class Service extends Vue {
  // msg!: string;
}
