<template>
  <div>
    <TopPageButton bgColor="background-color: var(--darkblue)" />
    <div class="footer bg-secondary">
      <div
        class="footer_container d-lg-flex justify-content-lg-center align-items-lg-center"
      >
        <p class="copyright">
          Copyright @<br class="break_sp" />
          Culture Images Archive Co., Ltd.<br class="break_sp" />
          All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import TopPageButton from "@/components/atoms/TopPageButton.vue";

@Options({
  components: {
    TopPageButton
  }
})
export default class Footer extends Vue {}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  color: var(--white);
  font-size: 0.9rem;
}
.footer_container {
  max-width: 980px;
  margin: 0 auto;
  height: 60px;
}
.footer_logo {
  display: flex;
  height: 40px;
  img {
    height: 100%;
    padding-right: 8px;
  }
  p {
    line-height: 40px;
  }
}
.copyright {
  margin: 0;
}
// mobile & tablet
@media (max-width: 992px) {
  .footer_container {
    height: auto;
    padding: 16px;
  }
  .footer_logo {
    justify-content: center;
  }
  .copyright {
    text-align: center;
    padding-top: 16px;
  }
}
// iPad pro等
@media (min-width: 992px) and (max-width: 1200px) {
  .footer_container {
    margin: 0 80px 0 40px;
  }
}
</style>
