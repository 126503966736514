<template>
  <transition name="fade">
    <div id="messageModal" class="msg_cover msg_hide" :style="bgColor">
      <p>{{ msg }}</p>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
    bgColor: String
  }
})
export default class MessageModal extends Vue {
  msg!: string;
  bgColor!: string;
}
</script>

<style scoped lang="scss">
.msg_cover {
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 10px 0;
  line-height: 40px;
  font-size: 1.2rem;
  background: var(--darkblue);
  opacity: 0.8;
  color: white;
  margin-left: -6%;
  z-index: 1000;
}
.msg_hide {
  top: -200px;
  transition-property: top;
  transition-duration: 1.2s;
  transition-timing-function: ease-in-out;
}
.msg_appear {
  top: 0;
  transition-property: top;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
}
</style>
