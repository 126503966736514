<template>
  <div id="section_practicalUse" class="content anchor">
    <div class="content_header">
      <p>活用例</p>
    </div>
    <div class="content_body container">
      <div class="row">
        <div class="col-sm">
          <p class="content_body__header text-sm-left">
            超高性能VRカメラの<br class="break__sp" />
            活用の場を広げる<br />
            パノラマ撮影映像
          </p>
          <p class="content_body__description">
            360度撮影以外の利用法として、パノラマ撮影機能を実装。縦方向のパノラマ撮影画像（右図参照）<br />
            <br />
            360度撮影のみならずVRカメラのさらなる活用が見込まれる。活用例として、鉄塔、風力発電の保守点検用途の可能性あり。
          </p>
        </div>
        <div class="col-sm image__base">
          <img
            class="content_body__image"
            v-lazy="require('@/assets/tower.jpg')"
            alt=""
          />
          <p class="image__caption">200K画像</p>
          <div class="image__up">
            <div class="balloon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class PracticalUse extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.content_body {
  text-align: center;
  &__description {
    text-align: start;
  }
  &__image {
    width: 100%;
    object-fit: cover;
    @media (min-width: 576px) and (max-width: 767px) {
      height: 90vw;
    }
  }
}
.image {
  &__base {
    position: relative;
  }
  &__caption {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: var(--white);
    @media (max-width: 575px) {
      bottom: 2rem;
      right: 0;
      transform: unset;
    }
  }
  &__up {
    width: 300px;
    height: 300px;
    position: absolute;
    left: -240px;
    bottom: 40px;
  }
  @media (max-width: 575px) {
    &__base {
      margin-bottom: 240px;
    }
    &__up {
      bottom: -270px;
      left: calc(50vw - 150px);
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    &__base {
      margin-bottom: calc(142px + 10vw);
    }
    &__up {
      bottom: calc(-142px - 10vw);
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    &__base {
      margin-bottom: calc(970px - 98vw);
    }
    &__up {
      bottom: calc(98vw - 970px);
    }
  }
}
.balloon {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  color: var(--white);
  background-image: url("../assets/tower_zoom.jpg");
  background-position: right -40px center;
  background-size: cover;
  border: 4px solid var(--lightblue);
}
.balloon::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  right: 2px;
  top: 2px;
  border-left: 50px solid var(--lightblue);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform: rotate(-45deg);
  @media (max-width: 575px) {
    right: 125px;
    top: -70px;
    border-bottom: 50px solid var(--lightblue);
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    transform: none;
  }
}
</style>
