<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
:root {
  --white: #fff;
  --lightgray: #f1f1f1;
  --bluegray: #d0d8dc;
  --darkgray: #222a2f;
  --lightblue: #0071c1;
  --darkblue: #0f588b;
  --primary: #0071c1;
  --secondary: #0f588b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
}
#app {
  color: var(--darkgray);
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", Meiryo, sans-serif;
}
.content {
  max-width: 980px;
  margin: 100px auto;
  padding-bottom: 100px;
  @media (max-width: 768px) {
    margin: 80px auto;
  }
}
.content_header {
  text-align: center;
  p {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;
    font-size: 2rem;
    font-weight: bold;
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 5px;
      background-color: var(--lightblue);
      position: absolute;
      bottom: 0;
      left: calc(50% - 10px);
    }
  }
}
.content_header__home {
  font-size: 1.5rem;
  margin-bottom: 48px;
  .en_title {
    font-family: monospace;
    font-size: 56px;
    transform: scaleY(1.2);
    color: var(--darkblue);
    opacity: 0.2;
    margin-bottom: -40px;
  }
}
.content_body {
  &__header {
    margin-bottom: 40px;
    font-size: 1.5rem;
    font-weight: bold;
    @media (max-width: 767px) {
      margin: 24px 0;
      font-size: 1.4rem;
    }
  }
  &__description {
    margin: 40px auto;
    font-size: 1.2rem;
    @media (max-width: 767px) {
      margin: 24px auto;
      font-size: 1.1rem;
    }
  }
}
.blue_line {
  background: linear-gradient(transparent 60%, #cce5ff 0%) repeat scroll 0 0;
}
.anchor {
  display: block;
  padding-top: 100px;
  margin-top: -100px;
  @media (max-width: 768px) {
    padding-top: 80px;
    margin-top: -80px;
  }
}
.bg_lightgray {
  background-color: var(--lightgray);
}
.break {
  &_sp {
    display: none;
  }
  @media (max-width: 576px) {
    &_sp {
      display: block;
    }
    &_pc {
      display: none;
    }
  }
}
.btn {
  min-width: 120px;
}

// bootstrap
$primary: #0071c1;
$secondary: #0f588b;
$success: #99c261;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #e8f6ff;
$dark: #343a40;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  warning: $warning,
  danger: $danger,
  info: $info,
  light: $light,
  dark: $dark
);

// breakpoint サイズメモ
// xs: 0 - 575px
// sm: 576 - 767px
// md: 768 - 991px
// lg: 992 - 1200px
// xl: 1200px -

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>
